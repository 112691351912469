import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
    Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoadingButton from '@mui/lab/LoadingButton';

const stripePromise = loadStripe("pk_test_51KaY7LIfXU65Dhxrxgzudwcn3VNKn382PT9al07YKhCwNqsvmhA5WZOO13Zev7jZzvl7oNKiMDjxD31uWOc55Xhs00QDt8Lh5k");


function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // TODO Make sure to change this to your payment completion page
                return_url: window.location.protocol + "//" + window.location.host + "/code.gen.view",
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <div className="mt-3">
                <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="outlined"
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    sx={{ textTransform: "capitalize", fontSize:"1.05em" }}>
                    Pay Now
                </LoadingButton>
                {message && <div id="payment-message">{message}</div>}
            </div>
        </form>
    );
}

export default function Billing(props) {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("http://127.0.0.1/v1/billing", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ name: "Option 0", price: 5000, currency: "usd" })
        })
            .then((res) => res.json())
            .then((data) => {
                setClientSecret(data.client_secret)
            })
    }, [])

    const appearance = {
        theme: 'stripe',
    }

    const options = {
        clientSecret,
        appearance,
    }


    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div>
                        {/* <!-- Probably will stripe js library for this to avoid PCI issues --> */}
                        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                            <h1 className="display-4">Billing Information</h1>
                        </div>
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        )}
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}