import { useState } from "react";

export function useStorage(key, dvalue){
    const loadValue = (dvalue) =>{
        const val = localStorage.getItem(key) || dvalue
        return val
    }

    const [value, setValue] = useState(loadValue(dvalue))
    const storeValue = (key, value) =>{
        localStorage.setItem(key, value)
        setValue(value)
    }

    return {
        value,
        storeValue
    }
}