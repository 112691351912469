import { Badge } from "react-bootstrap";


export default function Controls({ labels, actions }) {

    return (
        <>
            <div className="d-flex">
            {labels.map((label, index) => <Badge pill
                key={index}
                bg="dark"
                className="me-3 w-50 p-2 text-white btn"
                onClick={actions[index]}
            >{label}</Badge>)}
            </div>
        </>
    )
}