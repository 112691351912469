import { useParams } from "react-router-dom";
import { Modal, Button, FormControl } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import { Badge } from "react-bootstrap";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { failFetch, fetchWrapper, getApiUrl } from "../App";

export function Review(_props) {
    const params = useParams()
    const [review, setReview] = useState({})
    const [show, setShow] = useState(false)

    const [reply, setReply] = useState()
    const [description, setDescription] = useState("")
    useEffect(() => {
        fetchReviews()
    }, [])

    const fetchReviews = () => {
        return fetchWrapper(getApiUrl("review") + "/v1/review/" + params.id, null, "GET")
            .then(resp => failFetch(resp))
            .then(review => {
                let [reply] = review.reply
                setReview(review)
                setReply(reply)
            })
    }

    const handleClose = () => {
        reply && (reply.description = description)
        setReply(reply)
        setShow(false)
    }

    const handleSave = () => {
        if (reply === undefined) {
            fetchWrapper(getApiUrl("review") + "/v1/review/reply", { description: description, review_id: review.id }, "POST")
                .then(resp => failFetch(resp))
                .then(_reply => handleClose())
                .catch(error => console.log("Show error", error))

        } else {            
            reply.description = description
            fetchWrapper(getApiUrl("review") + "/v1/review/reply", { description: description, review_id: review.id }, "PATCH")
                .then(resp => failFetch(resp))
                .then(_reply => handleClose())
                .catch(error => console.log("Show error", error))
        }
    }

    const handleOnChange = (value) => { setDescription(value) }

    const handleShow = () => { setShow(true) }

    const replyView = () => {
        return (
            <>
                <h6 className="fw-bold">Response:</h6>
                <IconButton
                    aria-label="settings"
                    className="float-end"
                    onClick={handleShow}>
                    <EditIcon
                        fontSize="large" />
                </IconButton>
                <div>
                    <div className="p-2">
                        <p>{reply?.description}</p>
                    </div>
                    <Modal show={show} onHide={handleClose} className="modal-dialog-centered">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Reply</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormControl as="textarea" aria-label="reply" defaultValue={reply?.description} onChange={(event) => handleOnChange(event.target.value)} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>
                                Cancel
                            </Button>
                            <Button
                                ariant="primary"
                                onClick={handleSave}
                                sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )

    }

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="shadow mt-5">
                        <div className="card-body">
                            <div className="d-flex mb-3">
                                <Badge bg="secondary">
                                    <h5>{review.rating}</h5>
                                    <Rating
                                        max={1}
                                        readOnly
                                        value={1}
                                    />
                                </Badge>
                                <div>
                                    <h5 className="card-title ms-3 fs-3">{review.title}</h5>
                                    <h5 className="ms-3 fs-3">{review.reviewer}</h5>
                                </div>
                            </div>
                            <p className="card-text lead">{review.description}</p>
                            <div className="card-footer">
                                {replyView()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}