import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "../useAuth";
import { ImageProxy } from "./image.assets.component";

function AuthenticationLoadingPage({ children }) {
    const { user, setUser, authObserver } = useAuth()
    useEffect(() => {
        return authObserver(setUser)
    }, [user])

    if (user) {
        return children
    }

    return (
        <>
            <div className="d-flex justify-content-center">
                <ImageProxy src={process.env.PUBLIC_URL + "/logo.png"} className="icon-xl rotate" style={{marginTop: "25%"}} alt="Authentic Review" />
            </div>
        </>
    )
}

export default function Gate({ children }) {
    const { user, authObserver } = useAuth()
    const location = useLocation();
    const navigator = useNavigate()
    useEffect(() => {
        return authObserver(user => {
            if (!user) navigator("/")
            else navigator(location.pathname + location.search)
        })
    }, [user])

    return <AuthenticationLoadingPage>{children}</AuthenticationLoadingPage>
}