import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Badge } from "react-bootstrap";
import useAuth from "../useAuth";
import Gate from "./gate.component";
import { Rating, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { formatUrl, failFetch, fetchWrapper, formatAddress, getApiUrl } from "../App";
import { DataLoadingScreen } from "./loading.screen.component";

function ReviewCard({ review, reply }) {
    return (
        <div className="shadow mb-2">
            <div className="card-body">
                <div className="d-flex mb-3">
                    <Badge bg="secondary">
                        <h5>{review.rating}</h5>
                        <Rating
                            max={1}
                            readOnly
                            value={1}
                        />
                    </Badge>
                    <div>
                        <h5 className="card-title ms-3 fs-3">{review.title}</h5>
                        <p className="ms-3 text-muted">{review.reviewer}</p>
                    </div>
                </div>
                <p className="card-text lead">{review.description}</p>
                {
                    reply &&
                    <div className="p-2 card-footer">
                        <p className="fw-bold">Business response:</p>
                        <p>{reply}</p>
                    </div>
                }
            </div>
        </div>
    )
}

function BusinessReviewCard({ review, reply }) {
    const navigator = useNavigate()
    const navigate = (to) => {
        navigator(to)
    }

    return (
        <>
            <div className="shadow mb-2">
                <div className="card-body">
                    <div className="d-flex mb-3">
                        <Badge bg="secondary">
                            <h5>{review.rating}</h5>
                            <Rating
                                max={1}
                                readOnly
                                value={1}
                            />
                        </Badge>
                        <div>
                            <h5 className="card-title ms-3 fs-3">{review.title}</h5>
                            <p className="ms-3 text-muted">{review.reviewer}</p>
                        </div>
                    </div>
                    <p className="card-text lead">{review.description}</p>
                    {
                        reply &&
                        <div className="p-2 card-footer">
                            <p className="fw-bold">Your response:</p>
                            <p>{reply}</p>
                        </div>
                    }
                    <div>
                        <Button
                            onClick={() => navigate("/review.view/" + review.id)}
                            className="text-muted border-secondary mt-3"
                            variant="outlined"
                            sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>Respond</Button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default function Reviews() {
    const [business, setBusiness] = useState()
    const [reviews, setReviews] = useState([])
    const [more, setMore] = useState(true)

    const { user, setUser, authObserver } = useAuth()
    const [page, setPage] = useState(0)
    const [noData, setNoData] = useState(false)

    const size = 100
    const params = useParams()
    const fetchReviews = (biz) => {
        let inner_business = biz || business
        return fetchWrapper(getApiUrl("review") + "/v1/review/bid/" + inner_business.id + "?page=" + page + "&size=" + size, null, "GET")
            .then(resp => failFetch(resp))
            .then(({ reviews: inner_review, next_page }) => {
                setMore(next_page > 0)
                setPage(next_page)
                setReviews(reviews.concat(inner_review))
            })
    }

    const loadBusiness = () => {
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/" + params.id, null, "GET")
            .then(resp => failFetch(resp))
            .then(business => {
                setBusiness(business)
                return Promise.resolve(business)
            })
            .then(business => fetchReviews(business))
            .catch(error => {
                setNoData(true)
            })
    }

    useEffect(() => {
        loadBusiness()
        return authObserver(setUser)
    }, [user])

    const view = (review) => {
        let [reply] = review.reply
        if (user) {
            return (<Gate><BusinessReviewCard review={review} key={review.id} reply={reply?.description} /></Gate>)
        }
        return (<ReviewCard review={review} key={review.id} reply={reply?.description} />)
    }

    let { address: [address] } = business || { address: [] }
    return (
        <DataLoadingScreen shouldRender={business} noData={noData}>
            <div className="row bg-secondary shadow mt-4 mb-5 text-center">
                <div className="col-sm-12 col-md-8 col-lg-6 card-body">
                    <h3 className="fs-2 text-white">{business?.name}</h3>
                    <h4 className="fs-5 text-white">{address && formatAddress([address] || [{}])}</h4>
                    <a className="fs-5 text-white" href={business && formatUrl(business.url)}>{business?.url}</a>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-1 col-lg-2">
                </div>
                <div className="col-sm-12 col-md-10 col-lg-8">
                    <InfiniteScroll
                        className="card-flex"
                        dataLength={reviews.length}
                        next={fetchReviews}
                        hasMore={more}>
                        {reviews.map(review => (
                            view(review)
                        ))}
                    </InfiniteScroll>
                </div>
                <div className="col-sm-0 col-md-1 col-lg-2">
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </DataLoadingScreen>
    )

}