import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { fetchWrapper, getApiUrl } from "../App";
import { CircularProgress } from "@mui/material";
import "../styles/subscribe.css"

export default function Subscribe(props) {
    const [subscription, setSubscription] = useState("price_1KbEozIfXU65DhxrPVK1SKme")
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function handleOnSubmit(event) {
        event.preventDefault();
        setIsLoading(true)
        fetchWrapper(getApiUrl("billing")+"/v1/billing", { price_id: subscription }, "POST")
            .then(res => res.json())
            .then(data => {
                window.location.assign(data.redirect_url)
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                setErrorMessage("Something didn't work. Please try again")
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1>Subscription Options</h1>
                        <p className="lead">Choose the subscription option that meets your budget.</p>
                        {errorMessage && (<p className="text-danger lead">{errorMessage}</p>)}
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <form onSubmit={(event) => handleOnSubmit(event)}>
                        <div className="card-deck mb-3">
                            <div className="mb-4 shadow-sm text-center">
                                <div className="card-header bg-dark">
                                    <h4 className="my-0 font-weight-normal text-light">Option 0</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">$50 <small className="text-muted">/ mo</small></h1>
                                    <input type="radio" onClick={() => setSubscription("price_1KbEozIfXU65DhxrPVK1SKme")} defaultChecked name="subscription" className="btn btn-lg btn-block btn-outline-primary" />
                                </div>
                            </div>
                            <div className="mb-4 shadow-sm text-center">
                                <div className="card-header bg-dark">
                                    <h4 className="my-0 font-weight-normal text-light">Option 1</h4>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing-card-title">$550 <small className="text-muted">/ yr</small></h1>
                                    <input onClick={() => setSubscription("price_1KaYO5IfXU65Dhxr1jSbDIER")} type="radio" name="subscription" className="btn btn-lg btn-block btn-outline-primary" />
                                </div>
                            </div>
                            <div className="mt-5 text-center">
                                <LoadingButton
                                    loadingIndicator={<CircularProgress size={20}
                                        sx={{ color: "#fff" }} />
                                    }
                                    loading={isLoading}
                                    type="submit"
                                    className="bg-dark"
                                    variant="outlined"
                                    sx={{ color: "white", textTransform: "capitalize", fontSize:"1.05em" }}>
                                    Checkout
                                </LoadingButton>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div></>
    )

}