import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "./error.component";
import { ToastDecor } from "./toast.decor.component";

export default function PasswordReset() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")

    const [showToast, setShowToast] = useState(false)
    const { resetPassword } = useAuth()
    const navigate = useNavigate()

    const onDismiss = () => {
        navigate("/sign.in.view")
    }
    const handleOnSubmit = (event) => {
        setLoading(true)
        event.preventDefault();
        resetPassword(email)
            .then(() => setShowToast(true))
            .catch(() => {
                setLoading(false)
                setShowToast(true)
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="d-flex justify-content-center">
                        <span className="bg-dark icon-md me-2 rounded p-1"><img src={process.env.PUBLIC_URL + "/logo0.svg"} alt="Authentic Review" /></span>
                        <p className="display-6">Authentic Review</p>
                    </div>
                    <div className="shadow">
                        <div className="card-body">
                            <div>
                                <h6 className="display-6">Password Reset</h6>
                            </div>
                            <span className="clearfix"></span>
                            <form onSubmit={(event) => handleOnSubmit(event)}>
                                <div className="form-group mb-4">
                                    <label className="form-control-label">Email</label>
                                    <div className="input-group">
                                        <input required onChange={(event) => setEmail(event.target.value)} type="email" name="email" className="form-control" id="input-email" placeholder="name@example.com" />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <LoadingButton
                                        loadingIndicator={<CircularProgress size={20}
                                            sx={{ color: "#fff" }} />
                                        }
                                        loading={loading}
                                        type="submit"
                                        className="bg-dark w-50"
                                        variant="contained"
                                        sx={{ color: "white", textTransform: "capitalize", fontSize: "1.05em" }}>Reset Password</LoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    {showToast && <ToastDecor message={"A password reset email will be sent to the email if it's registered."} onDismiss={onDismiss}/>}
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
        </>
    );

}