import { FormControl, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { MenuItem } from "@mui/material";
import {
    Select, InputLabel
} from "@mui/material";
import { BusinessContext, failFetch, fetchWrapper, getApiUrl } from "../App";

export function BusinessInfoForm({ handleOnSubmit, setName, setUrl, setAddress1, setAddress2, setCity, setCountry, setState, setZip, country, state }) {
    const business  = useContext(BusinessContext)
    const [address] = business?.address || [{}]

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="shadow mt-5">
                        <div className="card-body">
                            <div className="mb-4">
                                <h3 className="display-6">Business Information</h3>
                            </div>
                            <span className="clearfix"></span>
                            <form onSubmit={(event) => handleOnSubmit(event)}>
                                <div className="form-group mb-3">
                                    <label className="form-control-label">Business Name</label>
                                    <div className="input-group">
                                        <input required defaultValue={business.name} onChange={(event) => setName(event.target.value)} type="text" name="company" className="form-control" id="input-company" placeholder="Amazing Inc" />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-control-label" htmlFor="address1">Internet Address <span className="text-muted">(Optional)</span></label>
                                    <div className="input-group">
                                        <input defaultValue={business.url} onChange={(event) => setUrl(event.target.value)} type="text" name="url" className="form-control" id="url" placeholder="www.amazinginc.com" />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-control-label" htmlFor="address1">Address</label>
                                    <div className="input-group">
                                        <input required defaultValue={address?.address1} onChange={(event) => setAddress1(event.target.value)} type="text" name="address1" className="form-control" id="address1" placeholder="124 Main st" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address2">Address 2 <span className="text-muted">(Optional)</span></label>
                                    <input defaultValue={address?.address2} onChange={(event) => setAddress2(event.target.value)} type="text" className="form-control" name="address2" id="address2" placeholder="Apartment or suite" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address2">City</label>
                                    <input required defaultValue={address?.city} onChange={(event) => setCity(event.target.value)} type="text" className="form-control" name="city" id="city" placeholder="New York" />
                                </div>
                                <div className="row">
                                    <FormControl className="col-md-5 ms-2 mb-3">
                                        <InputLabel>Country</InputLabel>
                                        <Select onChange={({ target: { value: country } }) => setCountry(country)}
                                            required
                                            value={address?.country || country}
                                            label="country"
                                            id="country">
                                            <MenuItem value="US">United States</MenuItem>
                                        </Select>
                                        <div className="invalid-feedback">
                                            Please select a valid country.
                                        </div>
                                    </FormControl>
                                    <FormControl className="col-md-5 ms-2 mb-3">
                                        <InputLabel>State</InputLabel>
                                        <Select onChange={({ target: { value: state } }) => setState(state)}
                                            required
                                            value={address?.state || state}
                                            label="state"
                                            id="state">
                                            <MenuItem value="AL">Alabama</MenuItem>
                                            <MenuItem value="AK">Alaska</MenuItem>
                                            <MenuItem value="AZ">Arizona</MenuItem>
                                            <MenuItem value="AR">Arkansas</MenuItem>
                                            <MenuItem value="CA">California</MenuItem>
                                            <MenuItem value="CO">Colorado</MenuItem>
                                            <MenuItem value="CT">Connecticut</MenuItem>
                                            <MenuItem value="DE">Delaware</MenuItem>
                                            <MenuItem value="DC">District Of Columbia</MenuItem>
                                            <MenuItem value="FL">Florida</MenuItem>
                                            <MenuItem value="GA">Georgia</MenuItem>
                                            <MenuItem value="HI">Hawaii</MenuItem>
                                            <MenuItem value="ID">Idaho</MenuItem>
                                            <MenuItem value="IL">Illinois</MenuItem>
                                            <MenuItem value="IN">Indiana</MenuItem>
                                            <MenuItem value="IA">Iowa</MenuItem>
                                            <MenuItem value="KS">Kansas</MenuItem>
                                            <MenuItem value="KY">Kentucky</MenuItem>
                                            <MenuItem value="LA">Louisiana</MenuItem>
                                            <MenuItem value="ME">Maine</MenuItem>
                                            <MenuItem value="MD">Maryland</MenuItem>
                                            <MenuItem value="MA">Massachusetts</MenuItem>
                                            <MenuItem value="MI">Michigan</MenuItem>
                                            <MenuItem value="MN">Minnesota</MenuItem>
                                            <MenuItem value="MS">Mississippi</MenuItem>
                                            <MenuItem value="MO">Missouri</MenuItem>
                                            <MenuItem value="MT">Montana</MenuItem>
                                            <MenuItem value="NE">Nebraska</MenuItem>
                                            <MenuItem value="NV">Nevada</MenuItem>
                                            <MenuItem value="NH">New Hampshire</MenuItem>
                                            <MenuItem value="NJ">New Jersey</MenuItem>
                                            <MenuItem value="NM">New Mexico</MenuItem>
                                            <MenuItem value="NY">New York</MenuItem>
                                            <MenuItem value="NC">North Carolina</MenuItem>
                                            <MenuItem value="ND">North Dakota</MenuItem>
                                            <MenuItem value="OH">Ohio</MenuItem>
                                            <MenuItem value="OK">Oklahoma</MenuItem>
                                            <MenuItem value="OR">Oregon</MenuItem>
                                            <MenuItem value="PA">Pennsylvania</MenuItem>
                                            <MenuItem value="RI">Rhode Island</MenuItem>
                                            <MenuItem value="SC">South Carolina</MenuItem>
                                            <MenuItem value="SD">South Dakota</MenuItem>
                                            <MenuItem value="TN">Tennessee</MenuItem>
                                            <MenuItem value="TX">Texas</MenuItem>
                                            <MenuItem value="UT">Utah</MenuItem>
                                            <MenuItem value="VT">Vermont</MenuItem>
                                            <MenuItem value="VA">Virginia</MenuItem>
                                            <MenuItem value="WA">Washington</MenuItem>
                                            <MenuItem value="WV">West Virginia</MenuItem>
                                            <MenuItem value="WI">Wisconsin</MenuItem>
                                            <MenuItem value="WY">Wyoming</MenuItem>
                                        </Select>
                                        <div className="invalid-feedback">
                                            Please provide a valid state.
                                        </div>
                                    </FormControl>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="address2">Zip</label>
                                        <input required defaultValue={address?.zip} onChange={(event) => setZip(event.target.value)} type="text" className="form-control" name="zip" id="zip" placeholder="20500" />
                                    </div>
                                </div>

                                <div className="mt-4 text-center">
                                    <IconButton type="submit" size="large">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}

export default function BusinessInfo({ businessListener }) {
    const business = useContext(BusinessContext)
    const [name, setName] = useState("")
    const [url, setUrl] = useState("")

    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [country, setCountry] = useState("US")

    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [city, setCity] = useState("")

    const navigate = useNavigate()
    function handleOnSubmit(event) {
        event.preventDefault()
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding",
            {
                name: name,
                email: business.email,
                url: url,
                address: {
                    address1: address1,
                    address2: address2,
                    country: country,
                    state: state,
                    zip: zip,
                    city: city
                }
            }, "PATCH")
            .then(resp => failFetch(resp))
            .then(business => {
                businessListener && businessListener(business)
                navigate("/image.assets.view")
            })
            .catch(error => console.log("Show error message", error))
    }

    return (
        <BusinessInfoForm
            handleOnSubmit={handleOnSubmit}
            setName={setName}
            setUrl={setUrl}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setCountry={setCountry}
            setCity={setCity}
            setState={setState}
            setZip={setZip}
            country={country}
            state={state}
        />
    )

}