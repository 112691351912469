import * as React from 'react';
import { CircularProgress } from "@mui/material";
export function DataLoadingScreen({ shouldRender, noData, children }) {
    if (noData) {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-10">
                    <img className='img-fluid' src={process.env.PUBLIC_URL + "/something_broke.jpg"} alt="Error" />
                </div>
            </div>
        )
    }
    return (
        shouldRender ?
            children :
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-10">
                    <CircularProgress className='position-absolute top-50 start-50' size={100} color="primary"/>
                </div>
            </div>
    )
}