import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../useAuth";
import { fetchWrapper, failFetch, getApiUrl } from "../App";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "./error.component";

export default function SignUp({ businessListener }) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const { signup } = useAuth()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState()

    const postBusiness = ({ uid, email }) => {
        return fetchWrapper(`${getApiUrl("onboarding")}/v1/onboarding`, { authentication_id: uid, email: email }, "POST")
            .then(resp => failFetch(resp))
            .then(business => {
                businessListener && businessListener(business)
                navigate("/business.info.view")
            })
            .catch(resp => {
                if (resp.status === 409) { // this indicates that the business deleted account, but never requested data purge so we just update their authentication id
                    return fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding", { authentication_id: uid, email: email }, "PATCH")
                        .then(resp => failFetch(resp))
                        .then(business => {
                            businessListener && businessListener(business)
                            navigate("/account.view")
                        })
                }
                throw resp
            }).catch(error => {
                setLoading(false)
                setErrorMessage("Something went awry. Please try again!")
            })
    }

    function handleOnSubmit(event) {
        setLoading(true)
        event.preventDefault()
        signup(email, password)
            .then(user => postBusiness(user))
            .catch(({ code }) => {
                setLoading(false)
                if ("auth/email-already-in-use" === code) {
                    setErrorMessage("Email is already registered. Please sign in")
                } else {
                    setErrorMessage("Something went awry. Please try again!")
                }
            })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6 mt-5">
                    <div className="d-flex justify-content-center">
                        <span className="bg-dark icon-md me-2 rounded p-1"><img src={process.env.PUBLIC_URL + "/logo0.svg"} alt="Authentic Review" /></span>
                        <p className="display-6">Authentic Review</p>
                    </div>
                    <div className="shadow">
                        <div className="card-body">
                            <div className="mb-4">
                                <h3 className="display-6">Create your account</h3>
                                <p className="text-muted">
                                    <small>Have an account?</small>
                                    <Link className="small fw-bold ms-2" to={"/sign.in.view"}>Sign In</Link>
                                </p>
                                <ErrorMessage message={errorMessage} color={"text-danger"} />
                            </div>
                            <span className="clearfix"></span>
                            <form onSubmit={(event) => handleOnSubmit(event)}>
                                <div className="form-group mb-4">
                                    <label className="form-control-label">Email</label>
                                    <div className="input-group">
                                        <input required onChange={(event) => setEmail(event.target.value)} type="email" name="email" className="form-control" id="input-email"
                                            placeholder="name@example.com"></input>
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label className="form-control-label">Password</label>
                                    <div className="input-group">
                                        <input required onChange={(event) => setPassword(event.target.value)} type="password" name="password" className="form-control" id="input-password"
                                            placeholder="Password" ></input>
                                    </div>
                                </div>
                                <div className="mb-4 text-center">
                                    <LoadingButton
                                        loadingIndicator={<CircularProgress size={20}
                                            sx={{ color: "#fff" }} />
                                        }
                                        loading={loading}
                                        type="submit"
                                        className="bg-dark w-50"
                                        variant="outlined"
                                        sx={{ color: "white", textTransform: "capitalize", fontSize: "1.05em" }}>
                                        Create Account</LoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    );

}