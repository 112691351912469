import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { FormControl, Form } from 'react-bootstrap';
import SendIcon from '@mui/icons-material/Send';
import Countdown from 'react-countdown';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchWrapper, getApiUrl } from "../App";


export default function CreateReview() {
    const [rating, setRating] = useState(1);
    const [reviewer, setReviewer] = useState(null);
    const [description, setDescription] = useState(null);  
    
    const [desCharLeft, setDesCharLeft] = useState(256);
    const [timeout, setTimeout] = useState(86400000)
    const [message, setMessage] = useState("Thanks for taking the time to write a review.")

    const [show, setShow] = useState(false)
    const [title, setTitle] = useState(null);  
    const navigate = useNavigate()
    
    const handleClose = () => {
        setShow(false)        
        navigate("/")
    }

    const showThankYouMessage = () => {
        setShow(true)
    }

    const handleTitle = (title) =>{
        setTitle(title)
    }

    const handleName = (value) => {
        setReviewer(value)
    }

    const handleDescription = (value) => {
        setDesCharLeft(256 - value.length)
        setDescription(value)
    }

    const searchParams = new URLSearchParams(window.location.search)
    const business_auth_id = searchParams.get("bid")
    const business_id = searchParams.get("bid0")
    
    const handleSubmit = () => {
        fetchWrapper(getApiUrl("review") + "/v1/review",
            { business_id: business_id, business_auth_id: business_auth_id, title: title, rating: rating, description: description, reviewer: reviewer }, "POST")
        showThankYouMessage()
    }

    return (
        <>
            <div className="row mt-5">
                <div className="col-md-3"> </div>
                <div className="col-md-6 text-center">
                    <div className="shadow-lg rounded-pill bg-secondary text-light">
                        <Countdown className='display-4' date={Date.now() + timeout} />
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div>
                        <Form.Label htmlFor="reviewer">Name</Form.Label>
                        <FormControl required style={{ width: 250 }} as="textarea" aria-label="reviewer" id="reviewer" maxLength={128} rows={1} onChange={(event) => handleName(event.target.value)} />
                    </div>
                    <div className="d-flex mt-3">
                        <div>
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <FormControl required style={{ width: 250 }} as="textarea" aria-label="title" maxLength={256} rows={1} onChange={(event) => handleTitle(event.target.value)} />
                        </div>
                        <div className="ms-5 mt-3">
                            <Typography component="legend">Rate</Typography>
                            <Rating
                                value={rating}
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <Form.Label htmlFor="description">Description <span className="text-muted">(Optional)</span></Form.Label>
                        <FormControl as="textarea" aria-label="description" maxLength={256} rows={10} onChange={(event) => handleDescription(event.target.value)} />
                    </div>
                    <span>Max: {desCharLeft}</span>
                    <Button className='float-end mt-3' variant="contained" endIcon={<SendIcon />} onClick={handleSubmit}>submit</Button>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>

            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <Modal show={show} onHide={handleClose} className="modal-dialog-centered">
                        <Modal.Header closeButton>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{message}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="contained" onClick={handleClose} >Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
        </>
    );
}