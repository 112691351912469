import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Button } from '@mui/material';
import "../styles/menu.css"
import { useLocation } from 'react-router-dom';
import useAuth from '../useAuth';

function MenuAction(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const { user, setUser, signout, authObserver } = useAuth()
    const location = useLocation()

    const navigate = (to) => {
        window.location.pathname = to
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const signOut = () => {
        setAnchorEl(null)
        signout()
    }


    React.useEffect(() => {
        const path = location.pathname
        setShow(path === "/")
        return authObserver(user => {
            setUser(user)
        })
    }, [location])

    if (user)
        return (
            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => navigate("/account.view")}>Account</MenuItem>
                    <MenuItem onClick={signOut}>Sign Out</MenuItem>
                </Menu>
            </div>
        )

    return (
        <>
            {
                show &&
                <div className="d-flex">
                    <Button sx={{ color: "white" }}>Are you a business?</Button>
                    <Button
                        onClick={() => navigate("/sign.in.view")}
                        sx={{ color: "white", textTransform: "capitalize" }}>Sign in</Button>
                    <Button onClick={() => navigate("/sign.up.view")}
                        sx={{ color: "white", textTransform: "capitalize" }}
                        variant="outlined" >Sign Up</Button>
                </div>
            }
        </>
    )

}

export default function MenuAppBar() {
    const navigate = (to) => {
        window.location.pathname = to
    }
    return (
        <Box sx={{ flexGrow: 1, marginBottom:10 }} className="bg-dark">
            <AppBar className="bg-dark">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => navigate("/")}>
                        <img src={process.env.PUBLIC_URL + "/logo0.svg"} alt="Authentic Review" className="icon-md me-2" />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <MenuAction auth={false} />
                </Toolbar>
            </AppBar>
        </Box>
    );
}
