import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { failFetch, fetchWrapper, getApiUrl } from "../App";
import { Button } from "@mui/material";
import useAuth from "../useAuth";
import { ImageProxy } from "./image.assets.component";

export default function CodeGen({ businessListener }) {
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)

    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const navigator = useNavigate()

    const { user, setUser, authObserver } = useAuth()
    const handleDownload = () => {
        window.location.assign(qrCodeUrl)
    }

    const postSubscription = codegenResponse => {
        fetchWrapper(getApiUrl("subscription") + "/v1/subscription", codegenResponse, "POST")
            .then(resp => failFetch(resp))
            .catch(error => console.log("Show error", error))
            
        return codegenResponse
    }

    const downloadQRCode = business => {
        // Check to see if this is a redirect back from Checkout
        businessListener && businessListener(business)
        const query = new URLSearchParams(window.location.search)
        if (query.get('success')) {
            setSuccess(true);
            const payload = { session_id: query.get('session_id'), business_auth_id: business?.authentication_id, business_id: business?.id, email: business?.email }
            fetchWrapper(getApiUrl("codegen") + "/v1/codegen", payload, "POST")
                .then(resp => failFetch(resp))
                .then(resp => postSubscription(resp)) //FIXME: this is a bad dependency. Subscription should be posted once stripe returns success
                .then(({ qrcode_url }) => setQrCodeUrl(qrcode_url))
                .catch(error => console.log("Show error message", error))
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Order canceled -- You can subscribe later when you're ready!"
            )
        }
    }

    const loadBusiness = user => {
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/" + user.uid, null, "GET")
            .then(resp => failFetch(resp))
            .then(business => downloadQRCode(business))
            .catch(error => console.log("Show error: ", error))
        setUser(user)
    }

    useEffect(() => {
        return authObserver(loadBusiness)
    }, [user]);

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <p className="lead">Download your unique QRCode</p>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    {success && <div className="featurette">
                        <ImageProxy src={qrCodeUrl} alt="QR Code" className="featurette-image img-fluid mx-auto" />
                        <div className="card-body text-center">
                            <Button
                                onClick={handleDownload}
                                className="bg-dark mx-5"
                                variant="outlined"
                                sx={{ color: "white", textTransform: "capitalize" }}>
                                Download
                            </Button>
                            <Button
                                onClick={() => navigator("/account.view")}
                                className="bg-dark mx-5"
                                variant="outlined"
                                sx={{ color: "white", textTransform: "capitalize", fontSize: "1.05em" }}>
                                Finish
                            </Button>
                        </div>
                    </div>}
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}