import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { formatAddress, formatUrl, getApiUrl } from "../App";
import { BusinessInfoForm } from "./business.info.component";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Controls from "./controls.component";
import useAuth from "../useAuth";
import { BusinessContext, fetchWrapper, failFetch } from "../App";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataLoadingScreen } from "./loading.screen.component";
import { ErrorMessage } from "./error.component";
import { ImageProxy } from "./image.assets.component";

export function BusinessInfoReadOnly({ business }) {
    const navigator = useNavigate()
    const onEdit = () => {
        navigator("/edit.business.info.view")
    }

    let { address: [address] } = business
    return (
        <div className="mb-3 text-center shadow">
            <div className="card-header bg-secondary text-white">
                <h4 className="font-weight-normal">Business</h4>
            </div>
            <div className="card-body">
                <h3 className="fs-2">{business?.name}</h3>
                <h4 className="fs-5">{address && formatAddress([address] || [{}])}</h4>
                <a className="fs-5" href={business && business.url && formatUrl(business.url)}>{business?.url}</a> <br />
                <IconButton
                    onClick={onEdit}
                    aria-label="settings">
                    <EditIcon
                        fontSize="large" />
                </IconButton>
            </div>
        </div>
    )
}

export function BusinessInfoEditable({ businessListener }) {
    const business = useContext(BusinessContext)
    const [address] = business.address || [{}]

    const [name, setName] = useState(business.name)
    const [address1, setAddress1] = useState(address.address1)
    const [address2, setAddress2] = useState(address.address2)

    const [country, setCountry] = useState(address.country)
    const [state, setState] = useState(address.state)
    const [city, setCity] = useState(address.city)

    const [zip, setZip] = useState(address.zip)
    const [url, setUrl] = useState(business.url)
    const navigate = useNavigate()

    function handleOnSubmit(event) {
        event.preventDefault();
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding",
            {
                name: name,
                email: business.email,
                url: url,
                address: {
                    address1: address1,
                    address2: address2,
                    country: country,
                    state: state,
                    zip: zip,
                    city: city
                }
            }, "PATCH")
            .then(resp => failFetch(resp))
            .then(business => {
                businessListener && businessListener(business)
                navigate("/account.view")
            })
            .catch(error => console.log("Show error message", error))
    }

    return (
        <BusinessInfoForm
            handleOnSubmit={handleOnSubmit}
            setName={setName}
            setUrl={setUrl}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setCountry={setCountry}
            setCity={setCity}
            setState={setState}
            setZip={setZip}
            country={country}
            state={state}
        />
    )

}

export function PasswordReadOnly(props) {
    const navigator = useNavigate()
    const onChange = () => {
        navigator("/edit.password.view")
    }
    return (
        <>
            <div className="mb-3 text-center shadow">
                <div className="card-header bg-secondary text-white">
                    <h4 className="font-weight-normal">Password</h4>
                </div>
                <div className="card-body">
                    <h1>******</h1>
                    <IconButton
                        onClick={onChange}
                        aria-label="edit password">
                        <EditIcon
                            fontSize="large" />
                    </IconButton>
                </div>
            </div>
        </>
    )
}

export function PasswordEditable(props) {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const navigate = useNavigate()
    const { updatePass } = useAuth()

    function handleOnSubmit(event) {
        event.preventDefault();
        updatePass(oldPassword, newPassword)
            .then(success => {
                console.log("Message from firebase auth: ", success)
                return success
            })
            .then(() => navigate("/account.view"))
            .catch(error => console.log("Show error message: ", error))
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                    <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1>Password Update</h1>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="mb-3 text-center shadow">
                        <div className="card-body">
                            <form onSubmit={(event) => handleOnSubmit(event)}>
                                <div className="form-group mb-4">
                                    <label className="form-control-label">Password</label>
                                    <div className="input-group">
                                        <input onChange={({ target: { value } }) => setOldPassword(value)} type="password" name="new-password" className="form-control" id="new-password"
                                            placeholder="Password" ></input>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label className="form-control-label">Verify Password</label>
                                    <div className="input-group">
                                        <input onChange={({ target: { value } }) => setNewPassword(value)} type="password" name="old-password" className="form-control" id="old-password"
                                            placeholder="Verify Password" ></input>
                                    </div>
                                </div>
                                <div className="mb-4 text-center">
                                    <Button
                                        type="submit"
                                        className="w-25 mt-3 bg-dark"
                                        variant="contained"
                                        sx={{ textTransform: "capitalize", color: "white", fontSize: "1.05em" }}>Update</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>

        </>
    )
}

export function ManageSubscription({ subscription }) {
    const [loading, setLoading] = useState(false)
    const navigator = useNavigate()
    const onSubscribe = () => {
        navigator("/subscribe.view")
    }

    const openPortal = ({ url }) => {
        setLoading(false)
        window.location.assign(url)
    }

    const manageSubscription = () => {
        setLoading(true)
        fetchWrapper(getApiUrl("subscription") + "/v1/subscription/portal/" + subscription.stripe_cusid, {}, "GET")
            .then(resp => failFetch(resp))
            .then(payload => openPortal(payload))
            .catch(error => {
                console.log("Show error", error)
                setLoading(false)
            })
    }

    if (subscription?.active) {
        return (

            <div className="mb-3 text-center shadow">
                <div className="card-header bg-secondary text-white">
                    <h4 className="my-0 font-weight-normal">Subscription</h4>
                </div>
                <div className="card-body">
                    {
                        subscription.frequency === 0 ?
                            <h1 className="card-title pricing-card-title">$50 <small className="text-muted">/ mo</small></h1>
                            : <h1 className="card-title pricing-card-title">$500 <small className="text-muted">/ yr</small></h1>
                    }
                    <LoadingButton
                        loading={loading}
                        onClick={manageSubscription}
                        aria-label="Manage subscription"
                        variant="contained"
                        sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>
                        Manage
                    </LoadingButton>
                </div>
            </div>
        )
    }

    return (
        <div className="card mb-3 text-center">
            <div className="card-header bg-secondary text-white">
                <h4 className="my-0 font-weight-normal">Subscription</h4>
            </div>
            <div className="card-body">
                <Button
                    aria-label="subscribe"
                    variant="contained"
                    onClick={onSubscribe}
                    sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>Subscribe</Button>
            </div>
        </div>
    )
}

export default function Account({ businessListener }) {
    //TODO: implement code regeneration
    const [show, setShow] = useState(false)
    const [subscription, setSubscription] = useState({})
    const navigator = useNavigate()

    const [business, setBusiness] = useState()
    const { user, setUser, authObserver } = useAuth()
    const [qrcodeUri, setQrCodeUri] = useState("")
    const [noData, setNoData] = useState(false)

    const [errorMessage, setErrorMessage] = useState()
    useEffect(() => {
        return authObserver(setUser)
    })

    const handleClose = () => setShow(false)
    const showQRCodeModal = () => setShow(true)
    const handleGenerate = () => {
        setQrCodeUri(null)
        fetchWrapper(getApiUrl("codegen") + "/v1/codegen", { business_id: business.id, business_auth_id: business.authentication_id }, "PATCH")
            .then(resp => failFetch(resp))
            .then(codeGenResp => setQrCodeUri(codeGenResp.url))
            .catch(error => console.log("Show error: ", error))
    }

    const readReviews = () => navigator("/reviews.view/" + business.id)
    const deleteAccount = () => user.delete().catch(({ code }) => {
        if (code === "auth/requires-recent-login") {
            setErrorMessage("Please sign out and sign back in to perform this action")
        } else {
            setErrorMessage("Blimey! We screwed and don't what to say. So just try again")
        }
    })


    const handleDownload = () => {
        window.location.assign(qrcodeUri)
        setShow(false)
    }

    const checkSubscription = business => {
        fetchWrapper(getApiUrl("subscription") + "/v1/subscription/" + business.id, {}, "GET")
            .then(resp => failFetch(resp))
            .then(payload => setSubscription(payload))
            .catch(error => console.log("Show error", error))

        businessListener && businessListener(business)
        try {
            const { brand: [{ qrcode_uri }] } = business
            setQrCodeUri(qrcode_uri)
        } catch (error) {
            console.log("QR Code Error", error, business)
        }

        return business
    }

    const loadBusiness = user => {
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/" + user.uid, null, "GET")
            .then(resp => failFetch(resp))
            .then(business => checkSubscription(business))
            .then(business => setBusiness(business))
            .catch(error => {
                console.log("Show error: ", error)
                setNoData(true)
            })

        setUser(user)
    }

    useEffect(() => {
        return authObserver(loadBusiness)
    }, [user]);

    return (
        <DataLoadingScreen shouldRender={business} noData={noData}>
            <>
                <div className="row mt-3 mb-3">
                    <div className="col-sm-0 col-md-3 col-lg-5">
                    </div>
                    <div className="col-sm-0 col-md-6 col-lg-5">
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-2">
                        <Controls labels={["QrCode", "Reviews"]} actions={[showQRCodeModal, readReviews]} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-0 col-md-3 col-lg-4">
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="flex">
                            <BusinessInfoReadOnly business={business} />
                            <PasswordReadOnly />
                            <ManageSubscription subscription={subscription} />
                        </div>
                    </div>
                    <div className="col-sm-0 col-md-3 col-lg-4">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        {
                            subscription?.active
                                ?
                                <Modal show={show} onHide={handleClose} centered >
                                    <Modal.Header closeButton>
                                        <Modal.Title>QR Code</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ImageProxy src={qrcodeUri} />
                                    </Modal.Body>
                                    <Modal.Footer >
                                        <div className="d-flex">
                                            <Button
                                                variant="contained"
                                                className="bg-dark me-3"
                                                onClick={handleGenerate}
                                                sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>
                                                Generate
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="bg-dark ms-3"
                                                onClick={handleDownload}
                                                sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>
                                                Download
                                            </Button>
                                        </div>
                                    </Modal.Footer>
                                </Modal>
                                :                                
                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>No Active Subscription</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Please subscribe to gain this ability</p>
                                    </Modal.Body>
                                </Modal>
                        }
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-0 col-md-3 col-lg-4"> </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 text-center">
                        <ErrorMessage message={errorMessage} color={"text-info"} />
                        <Button variant="contained"
                            color="error"
                            onClick={deleteAccount}
                            sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>Delete Account</Button>
                    </div>
                    <div className="col-sm-0 col-md-3 col-lg-4 mb-3">
                    </div>
                </div>
            </>
        </DataLoadingScreen>
    )
}