import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "./error.component";

export default function SignIn() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState()
    const { signin } = useAuth()
    const { state } = useLocation();
    const navigate = useNavigate()

    const handleOnSubmit = (event) => {
        setLoading(true)
        event.preventDefault();
        signin(email, password)
            .then(() => {
                navigate(state?.path || "/account.view")
            }).catch(({ code }) => {
                setLoading(false)
                if (code === "auth/user-not-found") {
                    setErrorMessage("Seems like you're quite mysterious. Let's you sign up!\uD83D\uDE00")
                } else {
                    setErrorMessage("I have no clue what just happened. We might succeed if you try again. Please do \uD83D\uDE00")
                }
            })
    }


    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6 mt-5">
                    <div className="d-flex justify-content-center">
                        <span className="bg-dark icon-md me-2 rounded p-1"><img src={process.env.PUBLIC_URL + "/logo0.svg"} alt="Authentic Review" /></span>
                        <p className="display-6">Authentic Review</p>
                    </div>
                    <div className="shadow">
                        <div className="card-body">
                            <div className="mb-4">
                                <h3 className="display-6">Sign In</h3>
                                <p className="text-muted">
                                    <small>Not registered?</small>
                                    <Link className="small fw-bold ms-2" to={"/sign.up.view"}>Sign Up</Link>
                                </p>
                                <ErrorMessage message={errorMessage} color={"text-info"} />
                            </div>
                            <span className="clearfix"></span>
                            <form onSubmit={(event) => handleOnSubmit(event)}>
                                <div className="form-group mb-4">
                                    <label className="form-control-label">Email</label>
                                    <div className="input-group">
                                        <input required onChange={(event) => setEmail(event.target.value)} type="email" name="email" className="form-control" id="input-email" placeholder="name@example.com" />
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label className="form-control-label">Password</label>
                                    <div className="input-group mb-4">
                                        <input required onChange={(event) => setPassword(event.target.value)} type="password" name="password" className="form-control" id="input-password" placeholder="Password" />
                                    </div>
                                </div>

                                <div className="text-center mb-2">
                                    <Link
                                        className="small"
                                        sx={{ color: "white", textTransform: "capitalize", fontSize: "1.05em" }}
                                        to={"/password.reset.view"}>Forgot Password</Link>
                                </div>
                                <div className="text-center">
                                    <LoadingButton
                                        loadingIndicator={<CircularProgress size={20}
                                            sx={{ color: "#fff" }} />
                                        }
                                        loading={loading}
                                        type="submit"
                                        className="bg-dark w-50"
                                        variant="contained"
                                        sx={{ color: "white", textTransform: "capitalize", fontSize: "1.05em" }}>
                                        Sign In</LoadingButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    );

}