
import React, { useState } from "react"
import { ToastContainer, Toast } from "react-bootstrap"

export function ToastDecor({ message, onDismiss }) {
    const [show, setShow] = useState(true)

    const handleDismiss = () => {
        onDismiss()
        setShow(false)
    }

    return (
        <ToastContainer position="middle-center">
            <Toast onClose={handleDismiss} show={show} autohide >
                <Toast.Header>
                    <img
                        src={process.env.PUBLIC_URL + "/logo20.jpg"}
                        className="rounded me-2"
                        alt="logo"
                    />
                    <strong className="me-auto">Authentic Reviews</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}