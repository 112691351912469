import { useEffect, useState } from "react";
import { failFetch, fetchWrapper, getApiUrl } from "../App";
import { IconButton } from "@mui/material";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useParams } from "react-router-dom";
import { DataLoadingScreen } from "./loading.screen.component";

function AuthForm({ callbackFn }) {
    let authValue = ""
    const handleOnSubmit = (event) => {
        event.preventDefault()
        callbackFn(authValue)
    }
    return (
        <>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="shadow mt-5">
                        <div className="card-body">
                            <div className="mb-4">
                                <h3 className="display-6">Which Business ?</h3>
                            </div>
                            <span className="clearfix"></span>
                            <form onSubmit={handleOnSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-control-label mb-2">Please enter the name of the business to proceed</label>
                                    <div className="input-group">
                                        <input required onChange={(event) => authValue = event.target.value} type="text" name="company" className="form-control" id="input-company" placeholder="Amazing Inc" />
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <IconButton type="submit" size="large">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}

export default function ReviewGate({ children }) {
    const [token, setToken] = useState(false)
    const [business, setBusiness] = useState({})
    const [codeState, setCodeState] = useState("Loading")
    const code = useParams()?.code


    const callbackFn = (authValue) => {
        setToken(business.name.toLowerCase() === authValue.toLowerCase())
    }

    const businessId = new URLSearchParams(window.location.search).get("bid0")
    useEffect(() => {
        fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/" + businessId, {}, "GET")
            .then(response => response.json())
            .then(business => setBusiness(business))

        fetchWrapper(getApiUrl("subscription") + "/v1/subscription/" + businessId, {}, "GET")
            .then(response => failFetch(response))
            .then(subscription => {
                if (!subscription || !subscription.active) {
                    window.location.assign("/")
                }
            })

        fetchWrapper(getApiUrl("codegen") + "/v1/codegen/" + businessId, {}, "GET")
            .then(response => response.json())
            .then(qrcodeResp => setCodeState(qrcodeResp.code === code ? "Render" : "Error"))
    }, [])

    switch (codeState) {
        case "Loading":
            return <DataLoadingScreen shouldRender={false} children={token ? children : <AuthForm callbackFn={callbackFn} />} />
        case "Render":
            return <DataLoadingScreen shouldRender={true} children={token ? children : <AuthForm callbackFn={callbackFn} />} />
        case "Error":
        default:
            return (
                <div className="row">
                    <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                    <div className="col-sm-12 col-md-8 col-lg-6">
                        <div className="shadow mt-5">
                            <div className="card-body">
                                <p>The link you're trying visit has expired. Please patronize the business again to get a new link</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                </div>
            )
    }
}