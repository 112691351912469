import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/sigin.component";
import SignUp from "./components/signup.component";
import Home from './components/home.component';
import Account, { BusinessInfoEditable, PasswordEditable } from './components/acount.component';
import CodeGen from './components/codegen.component';
import Reviews from './components/reviews.component';
import Subscribe from './components/subscribe.component';
import Billing from './components/billing.component';
import BusinessInfo from './components/business.info.component';
import { Review } from './components/review.component';
import CreateReview from './components/reviews.create.component';
import MenuAppBar from './components/menu.component';
import Gate from './components/gate.component';
import ReviewGate from './components/review.gate.component';
import ImageAssetUpload from './components/image.assets.component';
import useAuth from './useAuth';
import { useStorage } from './useStorage';
import PasswordReset from './components/password.reset.component';

export const API_GATEWAY_URL = "https://authenticreviews.info"
export const EMPTY = {}
export const BusinessContext = React.createContext({ business: EMPTY })

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function getApiUrl(service) {
  if (development) {
    switch (service) {
      case "account":
        return "http://127.0.0.1:8200"

      case "billing":
        return "http://127.0.0.1:8000"

      case "codegen":
        return "http://127.0.0.1:8100"

      case "onboarding":
        return "http://127.0.0.1:8200"

      case "review":
        return "http://127.0.0.1:8400"

      case "subscription":
        return "http://127.0.0.1:8300"
    }
  }
  return API_GATEWAY_URL
}

export function formatAddress([{ address1, city, state, country, zip }]) {
  return address1 + ", " + city + " " + state + " " + zip + ", " + country
}

export function formatUrl(url) {
  if (url) {
    const idx = url.indexOf("/")
    if (idx > -1 && url.at(idx + 1) == "/")
      return "http://" + url.substring(idx + 2)

    return "http://" + url
  }

  return url
}

export function fetchWrapper(url, payload, method) {
  if (method.toLowerCase() == "get") {
    return fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json", "mode": "no-cors" }
    })
  }

  return fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json", "mode": "no-cors" },
    body: JSON.stringify(payload)
  })
}

export function failFetch(resp) {
  if (resp.ok) {
    return resp.json()
  }
  throw resp
}

function HomeReloadScreen({ children, authenticated }) {
  const { user, setUser, authObserver } = useAuth()

  useEffect(() => {
    return authObserver(setUser)
  }, [user])

  if (authenticated) {
    if (user) {
      return children
    }
    return (
      <>
        <section className='bg-dark'></section>
      </>
    )
  }

  return children
}

export default function App() {
  const [business, setBusiness] = useState(EMPTY)
  const { user, authObserver } = useAuth()
  const { value, storeValue } = useStorage("authenticated", false)


  const loadBusiness = (user) => {
    const authenticated = user !== null
    if (authenticated && business == EMPTY) {
      fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/" + user.uid, null, "GET")
        .then(resp => failFetch(resp))
        .then(business => {
          setBusiness(business)
        })
        .catch(error => {
          console.log("Don't show error: ", error)
        })
    }
    storeValue("authenticated", authenticated)
  }

  useEffect(() => {
    return authObserver(loadBusiness)
  }, [user])

  return (
    <>
      <HomeReloadScreen authenticated={value}>
        <Router>
          <div className='container'>
            <div className='row'>
              <MenuAppBar />
            </div>
            <BusinessContext.Provider value={business}>
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/password.reset.view' element={<PasswordReset />} />
                <Route path="/sign.in.view" element={<SignIn />} />
                <Route path="/sign.up.view" element={<SignUp businessListener={setBusiness} />} />
                <Route path="/account.view" element={<Gate><Account businessListener={setBusiness} /></Gate>} />
                <Route path="/code.gen.view" element={<Gate><CodeGen businessListener={setBusiness} /></Gate>} />
                <Route path="/reviews.view/:id" element={<Reviews />} />
                <Route path="/subscribe.view" element={<Gate><Subscribe /></Gate>} />
                <Route path="/billing.view" element={<Gate><Billing /></Gate>} />
                <Route path="/business.info.view" element={<Gate><BusinessInfo businessListener={setBusiness} /></Gate>} />
                <Route path="/edit.business.info.view" element={<Gate><BusinessInfoEditable /></Gate>} />
                <Route path="/image.assets.view" element={<Gate><ImageAssetUpload /></Gate>} />
                <Route path="/edit.password.view" element={<Gate><PasswordEditable /></Gate>} />
                <Route path="/review.view/:id" element={<Gate><Review /></Gate>} />
                <Route path="/create.review.view/:code" element={<ReviewGate><CreateReview /></ReviewGate>} />
              </Routes>
            </BusinessContext.Provider>
          </div>
        </Router>
      </HomeReloadScreen>
    </>
  );
}
