import FileUpload from "./file.upload/file.upload.component";
import { useContext, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { storage } from "..";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { BusinessContext, failFetch, fetchWrapper, getApiUrl } from "../App";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

export const ASSET_ROOT = "assets/"

export function ImageProxy({src, className}){
    const[loaded, setLoaded] = useState(true)

    const handleLoad = () =>{
        setLoaded(true)
    }

    return (
        <>
            {!loaded && <CircularProgress color="info" className={className}/>}
            <img src={src} onLoad={handleLoad} className={"img-fluid " + className}/>
        </>
    )
}

export default function ImageAssetUpload() {
    const [featureGraphic, setFeatureGraphic] = useState()
    const [brandLogo, setBrandLogo] = useState()
    const business = useContext(BusinessContext)

    const [isUploading, setUploading] = useState(false)
    const navigator = useNavigate()
    const updateBusinessImageAsset = ({ brand_logo_promise, feature_graphic_promise }) => {
        if (feature_graphic_promise == null) {
            brand_logo_promise
                .then(logo_link => fetchWrapper(
                    getApiUrl("onboarding") + "/v1/onboarding/brand",
                    { business_id: business.id, logo_uri: logo_link },
                    "PATCH")
                )
                .then(resp => failFetch(resp))
                .then(() => handleSkip(null))
                .catch(error => {
                    console.log("Show Error Message", error)
                    setUploading(false)
                })

        } else if (brand_logo_promise == null) {
            feature_graphic_promise
                .then(link => fetchWrapper(
                    getApiUrl("onboarding") + "/v1/onboarding/brand",
                    { business_id: business.id, feature_graphic_uri: link },
                    "PATCH")
                )
                .then(resp => failFetch(resp))
                .then(() => handleSkip(null))
                .catch(error => {
                    console.log("Show Error Message", error)
                    setUploading(false)
                })

        } else {
            Promise.all([brand_logo_promise, feature_graphic_promise])
                .then(([logo_link, feature_link]) => fetchWrapper(
                    getApiUrl("onboarding") + "/v1/onboarding/brand",
                    { business_id: business.id, feature_graphic_uri: feature_link, logo_uri: logo_link },
                    "PATCH")
                )
                .then(resp => failFetch(resp))
                .then(() => handleSkip(null))
                .catch(error => {
                    console.log("Show Error Message", error)
                    setUploading(false)
                })
        }
    }

    const handleSkip = (event) => {
        event?.preventDefault()
        setUploading(false)
        navigator("/subscribe.view")
    }

    const handleSubmit = (event) => {
        setUploading(true)
        event.preventDefault()
        const promises = []

        const assetLink = {}
        let brandLogoRef = null
        let featureGraphicRef = null

        if (featureGraphic) {
            const extension = featureGraphic.type.split("/")[1]
            featureGraphicRef = ref(storage, ASSET_ROOT + business.id + "_feature_graphic." + extension);
            promises.push(uploadBytes(featureGraphicRef, featureGraphic))
        }

        if (brandLogo) {
            const extension = brandLogo.type.split("/")[1]
            brandLogoRef = ref(storage, ASSET_ROOT + business.id + "_brand_logo." + extension);
            promises.push(uploadBytes(brandLogoRef, brandLogo))
        }

        if (promises.length) {
            Promise.all(promises)
                .then(([snapshot0, snapshot1]) => {
                    if ((featureGraphicRef && featureGraphicRef.fullpath) === (snapshot0 && snapshot0.fullpath) ||
                        (featureGraphicRef && featureGraphicRef.fullpath) === (snapshot1 && snapshot1.fullpath))
                        assetLink.feature_graphic_promise = getDownloadURL(featureGraphicRef)

                    if ((brandLogoRef && brandLogoRef.fullpath) === (snapshot1 && snapshot1.fullpath) ||
                        (brandLogoRef && brandLogoRef.fullpath) === (snapshot0 && snapshot0.fullpath))
                        assetLink.brand_logo_promise = getDownloadURL(brandLogoRef)
                    updateBusinessImageAsset(assetLink)
                })
                .catch(error => {
                    console.log("Show Error trying to upload image assets", error)
                })
        } else {
            setUploading(false)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <h6 className="display-6 mt-5 text-center">Upload Brand Image Assets</h6>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-0 col-md-2 col-lg-3">
                </div>
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <div className="shadow mt-5">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <FileUpload
                                    accept=".jpg,.png,.jpeg"
                                    label="1050x500 Feature Graphic"
                                    width={1050}
                                    height={500}
                                    updateFilesCb={setFeatureGraphic}
                                    title="Feature Graphic"
                                />
                                <FileUpload
                                    accept=".jpg,.png,.jpeg"
                                    label="320x100 Brand logo"
                                    width={320}
                                    height={100}
                                    updateFilesCb={setBrandLogo}
                                    title="Brand Logo"
                                />
                                <LoadingButton
                                    sx={{ textTransform: "capitalize" }}
                                    loadingIndicator={<CircularProgress size={20} sx={{ color: "#fff" }} />}
                                    loading={isUploading}
                                    type="submit"
                                    variant="contained"
                                    className="float-end bg-dark">Upload</LoadingButton>
                            </form>
                            <div>
                                <Button
                                    onClick={handleSkip}
                                    className="bg-dark"
                                    sx={{ textTransform: "capitalize", color: "#fff" }}>Skip</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-0 col-md-2 col-lg-3"> </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
        </>
    )
}