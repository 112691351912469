import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Carousel } from 'react-bootstrap'
import "../styles/home.css"
import { Button, Rating } from "@mui/material";
import { formatUrl, failFetch, fetchWrapper, getApiUrl } from "../App";
import { ImageProxy } from "./image.assets.component";

const fakeData = [
    { id: 0, review_count: 900, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/504", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 5 },
    { id: 1, review_count: 10, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/503", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 4.5 },
    { id: 2, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/500", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 3 },
    { id: 3, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/502", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 4, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/501", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 5, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/418", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 6, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/420", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 7, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/424", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 8, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/422", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 9, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/425", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
    { id: 10, review_count: 50, name: "Clever Corp", address: "12203 New Haven Ave.", url: "https://http.cat/426", brand: [{ logo_uri: process.env.PUBLIC_URL + "/logoproxy.png" }], average_rating: 1 },
]

function Top3({ brands }) {
    if (brands.length === 0) {
        return (
            <Carousel className="mt-3 mb-3 text-center" interval={5000} >
                <Carousel.Item key={"default"}>
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/feature_graphic.png"} alt="feature graphic" />
                    <Carousel.Caption>
                        <Rating
                            readOnly
                            value={5}
                        />
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }

    return (
        <Carousel className="mt-3 mb-3 text-center" interval={5000} >
            {
                brands.map(([brand, rating]) =>
                    <Carousel.Item key={brand.id}>
                        <ImageProxy className="img-fluid" src={brand?.feature_graphic_uri || process.env.PUBLIC_URL + "/featureproxy.png"} alt="feature graphic" />
                        <Carousel.Caption>
                            <Rating
                                readOnly
                                value={rating}
                            />
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            }
        </Carousel>
    )
}

function Businesses({ businesses, fetchData, more }) {
    if (businesses.length === 0)
        businesses = Array.from(fakeData)

    return (
        <InfiniteScroll
            className="card-flex"
            dataLength={businesses.length}
            hasMore={more}
            next={fetchData}>
            {businesses.map(business => <BusinessCard business={business} key={business.id} />)}
        </InfiniteScroll>
    )
}

function BusinessCard({ business }) {
    const [brand] = business.brand
    const navigator = useNavigate()

    const navigate = (to) => {
        navigator(to)
    }

    return (
        <div className="mb-2 shadow text-center">
            <ImageProxy className="m-2 img-fluid" src={brand?.logo_uri || process.env.PUBLIC_URL + "/logoproxy.png"} alt="brand logo" />
            <div className="card-body">
                <h3 className="fs-2">{business.name}</h3>
                <h4 className="fs-5">{business.address}</h4>
                <Rating
                    readOnly
                    value={business.average_rating}
                    precision={0.5}
                    className="mb-3"
                />
                <span className="text-muted"><p>{business.review_count} reviews</p></span>

                <div>
                    <Button
                        onClick={() => window.location.assign(formatUrl(business.url))}
                        className="text-dark border-secondary ms-4"
                        variant="outlined"
                        sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>Website</Button>
                    <Button
                        onClick={() => navigate("/reviews.view/" + business.id)}
                        className="text-dark border-secondary ms-4"
                        variant="outlined"
                        sx={{ textTransform: "capitalize", fontSize: "1.05em" }}>Reviews</Button>
                </div>
            </div>
        </div>
    )
}

export default function Home() {
    const [businesses, setBusinesses] = useState([])
    const [brands, setBrands] = useState([])
    const [more, setMore] = useState(true)

    const [page, setPage] = useState(0)
    const size = 100

    const fetchData = () => {
        return fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding?page=" + page + "&size=" + size, null, "GET")
            .then(resp => failFetch(resp))
            .then(({ businesses: inner_biz, next_page }) => {
                setMore(next_page > 0)
                setPage(next_page)
                setBusinesses(businesses.concat(inner_biz))
            })
    }

    const fetchTop3 = () => {
        return fetchWrapper(getApiUrl("onboarding") + "/v1/onboarding/top3", null, "GET")
            .then(resp => failFetch(resp))
            .then(business => {
                setBrands(business.map(({ brand: [brand0], average_rating }) => [brand0, average_rating]))
            })
    }

    useEffect(() => {
        fetchData()
        fetchTop3()
    }, [])
    return (
        <>
            <div className="row mb-3">
                <div className="col-sm-0 col-md-3 col-lg-2">
                </div>
                <div className="col-sm-12 col-md-6 col-lg-8">
                    <Top3 brands={brands} />
                </div>
                <div className="col-sm-0 col-md-3 col-lg-2">
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-10">
                    <Businesses businesses={businesses} fetchData={fetchData} more={more} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3"> </div>
                <div className="col-md-6">
                </div>
                <div className="col-md-3"> </div>
            </div>
            <footer className="pt-4 my-md-5 pt-md-5 border-top footer">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Authentic Review" className="icon-sm" />
                        <small className="d-block mb-3 text-muted">&copy; 2022 &middot; All rights Reserved</small>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <h5>About</h5>
                        <ul className="list-unstyled text-small">
                            <li><a className="text-muted" href="#">Team</a></li>
                            <li><a className="text-muted" href="#">Privacy</a></li>
                            <li><a className="text-muted" href="#">Terms</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}