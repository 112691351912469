import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAuth } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA4Z_jIDT4-aLxs2WWFnN5oNy6TSPAWm-k",
  authDomain: "authenticreviews-353101.firebaseapp.com",
  projectId: "authenticreviews-353101",
  storageBucket: "authenticreviews-353101.appspot.com",
  messagingSenderId: "235107721890",
  appId: "1:235107721890:web:4449b3058e2a35b9c7adfb",
  measurementId: "G-PF4813L4P6"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const storage = getStorage(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
